.SlideRight-appear {
	 transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -o-transform: translateX(300px);
    -ms-transform: translateX(300px);
    opacity: 0;
  }
  
  .SlideRight-appear.SlideRight-appear-active {
    opacity: 1;
     transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transition: all 0.7s linear 0.1s;
  }
  .SlideRight-enter {
      transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -o-transform: translateX(300px);
    -ms-transform: translateX(300px);
    opacity: 0;
  }
  .SlideRight-enter.SlideRight-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideRight-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideRight-leave.SlideRight-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -o-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      transition: all 0.7s linear;
  }

  
  .SlideLeft-appear {
    transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    opacity: 0;
  }
  
  .SlideLeft-appear.SlideLeft-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.7s linear 0.1s;
  }
  .SlideLeft-enter {
      opacity: 0;
      transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
  }
  .SlideLeft-enter.SlideLeft-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideLeft-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideLeft-leave.SlideLeft-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(300px);
      -webkit-transform: translateX(300px);
      -moz-transform: translateX(300px);
      -o-transform: translateX(300px);
      -ms-transform: translateX(300px);
      transition: all 0.7s linear;
  }


  .card-appear {
    opacity: 0;
  }
  
  .card-appear.card-appear-active {
    opacity: 1;
    transition: all 0.7s linear 0.1s;
  }
  .card-enter {
    opacity: 0;
  }
  .card-enter.card-enter-active {
      opacity: 1;
      transition: all 0.7s linear 0.1s;
  }
  .card-leave {
      opacity: 1.0;
  }
  .card-leave.card-leave-active {
      opacity: 0;
      transition: all 0.7s linear;
  }
