@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* @import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com'); */

.SlideIn-appear {

    opacity: 0;
  }
  
  .SlideIn-appear.SlideIn-appear-active {
    opacity: 1;

    transition: all 600ms linear;
  }
  .SlideIn-enter {
      opacity: 0;
      transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
  }
  .SlideIn-enter.SlideIn-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 2.2s linear 0.4s;
  }
  .SlideIn-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideIn-leave.SlideIn-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -moz-transform: translateX(-30px);
      -o-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transition: all 2.2s linear;
  }

  
  .SlideOut-appear {
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    opacity: 0;
  }
  
  .SlideOut-appear.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
  .SlideOut-enter {
      opacity: 0;
      transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
  }
  .SlideOut-enter.SlideOut-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.2s linear 0.4s;
  }
  .SlideOut-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideOut-leave.SlideOut-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(30px);
      -webkit-transform: translateX(30px);
      -moz-transform: translateX(30px);
      -o-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transition: all 0.2s linear;
  }

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
  
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-in {
  opacity: 0;
}

.fade-in-active {
  opacity: 1;
}

.fade-out {
  opacity: 1;
}

.fade-out-active {
  opacity: 0;
}
.SlideIn-appear {

    opacity: 0;
  }
  
  .SlideIn-appear.SlideIn-appear-active {
    opacity: 1;

    transition: all 600ms linear;
  }
  .SlideIn-enter {
      opacity: 0;
      transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
  }
  .SlideIn-enter.SlideIn-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 2.2s linear 0.4s;
  }
  .SlideIn-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideIn-leave.SlideIn-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -moz-transform: translateX(-30px);
      -o-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transition: all 2.2s linear;
  }

  
  .SlideOut-appear {
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    opacity: 0;
  }
  
  .SlideOut-appear.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
  .SlideOut-enter {
      opacity: 0;
      transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
  }
  .SlideOut-enter.SlideOut-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.2s linear 0.4s;
  }
  .SlideOut-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideOut-leave.SlideOut-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(30px);
      -webkit-transform: translateX(30px);
      -moz-transform: translateX(30px);
      -o-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transition: all 0.2s linear;
  }

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.SlideIn-appear {

    opacity: 0;
  }
  
  .SlideIn-appear.SlideIn-appear-active {
    opacity: 1;

    transition: all 600ms linear;
  }
  .SlideIn-enter {
      opacity: 0;
      transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
  }
  .SlideIn-enter.SlideIn-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 2.2s linear 0.4s;
  }
  .SlideIn-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideIn-leave.SlideIn-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -moz-transform: translateX(-30px);
      -o-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transition: all 2.2s linear;
  }

  
  .SlideOut-appear {
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    opacity: 0;
  }
  
  .SlideOut-appear.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
  .SlideOut-enter {
      opacity: 0;
      transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
  }
  .SlideOut-enter.SlideOut-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.2s linear 0.4s;
  }
  .SlideOut-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideOut-leave.SlideOut-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(30px);
      -webkit-transform: translateX(30px);
      -moz-transform: translateX(30px);
      -o-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transition: all 0.2s linear;
  }

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
  
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-in {
  opacity: 0;
}

.fade-in-active {
  opacity: 1;
}

.fade-out {
  opacity: 1;
}

.fade-out-active {
  opacity: 0;
}
.SlideIn-appear {

    opacity: 0;
  }
  
  .SlideIn-appear.SlideIn-appear-active {
    opacity: 1;

    transition: all 600ms linear;
  }
  .SlideIn-enter {
      opacity: 0;
      transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
  }
  .SlideIn-enter.SlideIn-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 2.2s linear 0.4s;
  }
  .SlideIn-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideIn-leave.SlideIn-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -moz-transform: translateX(-30px);
      -o-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transition: all 2.2s linear;
  }

  
  .SlideOut-appear {
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    opacity: 0;
  }
  
  .SlideOut-appear.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
  .SlideOut-enter {
      opacity: 0;
      transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
  }
  .SlideOut-enter.SlideOut-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.2s linear 0.4s;
  }
  .SlideOut-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideOut-leave.SlideOut-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(30px);
      -webkit-transform: translateX(30px);
      -moz-transform: translateX(30px);
      -o-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transition: all 0.2s linear;
  }

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.example-enter {
   opacity: 0.01;
 }

 .example-enter.example-enter-active {
   opacity: 1;
   transition: opacity 600ms ease-in;
 }


.example-exit {
   opacity: 1;
 }

.example-exit.example-exit-active {
   opacity: 0.01;
   transition: opacity 300ms ease-in;
 }

.example-enter {
   opacity: 0.01;
 }

 .example-enter.example-enter-active {
   opacity: 1;
   transition: opacity 600ms ease-in;
 }


.example-exit {
   opacity: 1;
 }

.example-exit.example-exit-active {
   opacity: 0.01;
   transition: opacity 300ms ease-in;
 }
.settings-enter {
    opacity: 0.01;
  }
  
  .settings-enter.settings-enter-active {
    opacity: 1;
    transition: opacity 500ms linear;
  }
  
  .settings-leave {
    opacity: 1;
  }
  
  .settings-leave.settings-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease;
  }

.example-enter {
   opacity: 0.01;
 }

 .example-enter.example-enter-active {
   opacity: 1;
   transition: opacity 300ms ease-in;
 }


.example-exit {
   opacity: 1;
 }

.example-exit.example-exit-active {
   opacity: 0.01;
   transition: opacity 300ms ease-in;
 }
.stripe-form {
    /* width: 30vw; */
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  .stripe-input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  .stripe-button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  .stripe-button:hover {
    filter: contrast(115%);
  }
  .stripe-button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    transform-origin: 10.4px 10.2px;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    transform-origin: 0px 10.2px;
    animation: loading 2s infinite ease;
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
.SlideRight-appear {
	 transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -o-transform: translateX(300px);
    -ms-transform: translateX(300px);
    opacity: 0;
  }
  
  .SlideRight-appear.SlideRight-appear-active {
    opacity: 1;
     transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transition: all 0.7s linear 0.1s;
  }
  .SlideRight-enter {
      transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -o-transform: translateX(300px);
    -ms-transform: translateX(300px);
    opacity: 0;
  }
  .SlideRight-enter.SlideRight-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideRight-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideRight-leave.SlideRight-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -o-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      transition: all 0.7s linear;
  }

  
  .SlideLeft-appear {
    transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    opacity: 0;
  }
  
  .SlideLeft-appear.SlideLeft-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.7s linear 0.1s;
  }
  .SlideLeft-enter {
      opacity: 0;
      transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
  }
  .SlideLeft-enter.SlideLeft-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideLeft-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideLeft-leave.SlideLeft-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(300px);
      -webkit-transform: translateX(300px);
      -moz-transform: translateX(300px);
      -o-transform: translateX(300px);
      -ms-transform: translateX(300px);
      transition: all 0.7s linear;
  }


  .card-appear {
    opacity: 0;
  }
  
  .card-appear.card-appear-active {
    opacity: 1;
    transition: all 0.7s linear 0.1s;
  }
  .card-enter {
    opacity: 0;
  }
  .card-enter.card-enter-active {
      opacity: 1;
      transition: all 0.7s linear 0.1s;
  }
  .card-leave {
      opacity: 1.0;
  }
  .card-leave.card-leave-active {
      opacity: 0;
      transition: all 0.7s linear;
  }

.SlideRight-appear {
	 transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -o-transform: translateX(300px);
    -ms-transform: translateX(300px);
    opacity: 0;
  }
  
  .SlideRight-appear.SlideRight-appear-active {
    opacity: 1;
     transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transition: all 0.7s linear 0.1s;
  }
  .SlideRight-enter {
      transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -o-transform: translateX(300px);
    -ms-transform: translateX(300px);
    opacity: 0;
  }
  .SlideRight-enter.SlideRight-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideRight-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideRight-leave.SlideRight-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -o-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      transition: all 0.7s linear;
  }

  
  .SlideLeft-appear {
    transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    opacity: 0;
  }
  
  .SlideLeft-appear.SlideLeft-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.7s linear 0.1s;
  }
  .SlideLeft-enter {
      opacity: 0;
      transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
  }
  .SlideLeft-enter.SlideLeft-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideLeft-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideLeft-leave.SlideLeft-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(300px);
      -webkit-transform: translateX(300px);
      -moz-transform: translateX(300px);
      -o-transform: translateX(300px);
      -ms-transform: translateX(300px);
      transition: all 0.7s linear;
  }


  .card-appear {
    opacity: 0;
  }
  
  .card-appear.card-appear-active {
    opacity: 1;
    transition: all 0.7s linear 0.1s;
  }
  .card-enter {
    opacity: 0;
  }
  .card-enter.card-enter-active {
      opacity: 1;
      transition: all 0.7s linear 0.1s;
  }
  .card-leave {
      opacity: 1.0;
  }
  .card-leave.card-leave-active {
      opacity: 0;
      transition: all 0.7s linear;
  }


  .card-appear {
    opacity: 0;
  }
  
  .card-appear.card-appear-active {
    opacity: 1;
    transition: all 0.5s ease-in 0.4s;
  }
  .card-enter {
    opacity: 0;
  }
  .card-enter.card-enter-active {
      opacity: 1;
      transition: all 0.5s ease-in 0.4s;
  }
  .card-leave {
      opacity: 1.0;
  }
  .card-leave.card-leave-active {
      opacity: 0;
      transition: all 0.5s linear;
  }

.react-datepicker-popper {
     z-index: 100 !important
     }

     .ui.dropdown.search{
          min-width: 250px;
     }
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #476DFA;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
