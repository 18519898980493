.SlideIn-appear {

    opacity: 0;
  }
  
  .SlideIn-appear.SlideIn-appear-active {
    opacity: 1;

    transition: all 600ms linear;
  }
  .SlideIn-enter {
      opacity: 0;
      transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
  }
  .SlideIn-enter.SlideIn-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 2.2s linear 0.4s;
  }
  .SlideIn-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideIn-leave.SlideIn-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -moz-transform: translateX(-30px);
      -o-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transition: all 2.2s linear;
  }

  
  .SlideOut-appear {
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    opacity: 0;
  }
  
  .SlideOut-appear.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
  .SlideOut-enter {
      opacity: 0;
      transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
  }
  .SlideOut-enter.SlideOut-enter-active {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transition: all 0.2s linear 0.4s;
  }
  .SlideOut-leave {
      opacity: 1.0;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
  }
  .SlideOut-leave.SlideOut-leave-active {
      opacity: 0;
      position: absolute;
      width: 100%;
      transform: translateX(30px);
      -webkit-transform: translateX(30px);
      -moz-transform: translateX(30px);
      -o-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transition: all 0.2s linear;
  }

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}