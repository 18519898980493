
  .card-appear {
    opacity: 0;
  }
  
  .card-appear.card-appear-active {
    opacity: 1;
    transition: all 0.5s ease-in 0.4s;
  }
  .card-enter {
    opacity: 0;
  }
  .card-enter.card-enter-active {
      opacity: 1;
      transition: all 0.5s ease-in 0.4s;
  }
  .card-leave {
      opacity: 1.0;
  }
  .card-leave.card-leave-active {
      opacity: 0;
      transition: all 0.5s linear;
  }
