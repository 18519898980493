/* Enter */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
  position:absolute;
  width:800px;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
 
}

/* Exit */
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}