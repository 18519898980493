.settings-enter {
    opacity: 0.01;
  }
  
  .settings-enter.settings-enter-active {
    opacity: 1;
    transition: opacity 500ms linear;
  }
  
  .settings-leave {
    opacity: 1;
  }
  
  .settings-leave.settings-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease;
  }

.example-enter {
   opacity: 0.01;
 }

 .example-enter.example-enter-active {
   opacity: 1;
   transition: opacity 300ms ease-in;
 }


.example-exit {
   opacity: 1;
 }

.example-exit.example-exit-active {
   opacity: 0.01;
   transition: opacity 300ms ease-in;
 }