
.example-enter {
   opacity: 0.01;
 }

 .example-enter.example-enter-active {
   opacity: 1;
   transition: opacity 600ms ease-in;
 }


.example-exit {
   opacity: 1;
 }

.example-exit.example-exit-active {
   opacity: 0.01;
   transition: opacity 300ms ease-in;
 }